import React from 'react'
//component imports
import SectionCards from '../UI/SectionCards'

//img imports
import aboutUsImg from "../../imgs/about-us-640x426.jpg"

let aboutSectionContents = {
    title: "About Recruiter Busters",
    img: aboutUsImg,
    subtitle: 'A site made by a frustrated recent grad looking for a job.',
    desc: "There isn't much to know about Recruiter Buster. It started as an idea after dealing with so many 'development' recruiters. 9 times out of 10 I would be ghosted by a job recruiter or I'd never hear back at all. I remember looking around for a website where I could review them. When I realized there weren't any or I decided to make it myself. Now it's here for everyone, where everyone can review a recruiter, how an interview went and anything else connected to job hunting.",
    alt: "Image of a frustrated person"
}

function About(props) {
  return (
    <>
        <SectionCards {...aboutSectionContents}/>
    </>
  )
}

export default About