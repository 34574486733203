import React, { useEffect, useState } from "react";

import axios from "axios";

import Card from "react-bootstrap/Card";

import Loading from "../UI/Loading";
const BASE_URL = axios.create({ baseURL: "https://recruiter-buster.onrender.com/" });

function RecruiterList() {
  const [recruiter, setRecruiter] = useState(null);
  const [stopEffect, setStopEffect] = useState(false);
  const [loading, setLoading] = useState(false);
  //fetch the recruiters and sort them based on their overall rating
  useEffect(() => {
    const fetchRecruiters = async () => {
      try {
        setLoading(true);
        const response = await BASE_URL.get(`recruiters`);
        if (Array.isArray(response.data)) {
          const recruiterRatings = response.data.map((recruiter) => {
            const overallRating =
              recruiter.overallRating.reduce((sum, rating) => sum + rating, 0) /
              recruiter.overallRating.length;
            return { ...recruiter, overallRating };
          });
          setRecruiter(recruiterRatings);
          setStopEffect(true);
        } else {
          console.log(response.err);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchRecruiters();
  }, [stopEffect]);

  if (!recruiter) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        recruiter
          .sort((a, b) => b.overallRating - a.overallRating)
          .map((recruit) => (
            <Card key={recruit._id}>
              <Card.Header>
                <Card.Title><a href={`/recruiters/${recruit._id}`}>{recruit.name}</a></Card.Title>
                <div className="star-rating">
                  {Math.round(recruit.overallRating * 10) / 10}
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <button
                        type="button"
                        key={index}
                        className={
                          index <= recruit.overallRating
                            ? "on stars"
                            : "off stars"
                        }
                      >
                        <span className="star">&#9733;</span>
                      </button>
                    );
                  })}
                </div>
              </Card.Header>
            </Card>
          ))
      )}
    </>
  );
}

export default RecruiterList;
