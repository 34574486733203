import React, { useState } from "react";
import Axios from "axios";
import '../../Config/axiosConfig';

//bootstrap imports
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com/users" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await BASE_URL.post("/login", { email, password });
      localStorage.setItem("token", response.data.token);
      //redirect occurs here
      window.location.href ='/user';
      console.log("logged in");
    } catch (err) {
      console.error(err);
      //display error to user here
    }
  };
  return (
    <>
      <h1 className="center bg-secondary text-light rounded">Log-In</h1>
      <Form className="bg-white mt-3 p-2 rounded sm" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className="text-dark">Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text className="text-dark">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className="text-dark">Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <a href="/reset-password">Forgot Password?</a>
        </Form.Group>
        <Button type="submit">
          Log In
        </Button>
      </Form>
    </>
  );
}

export default Login;