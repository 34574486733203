import React from "react";

//css imports
import "./SectionCards.css";

//component imports
import Button from "react-bootstrap/Button";

//bootstrap imports
import Card from "react-bootstrap/Card";

function SectionCards(props) {
  const redirecter = () => {
    return window.location.href="/create-review"
   }
  return (
    <Card bg="secondary">
      <Card.Header>
        <h1 className="center">{props.title}</h1>
      </Card.Header>

      <div className="title-img-position">
        <Card.Img variant="top" src={props.img} className="title-img" alt={props.alt}/>
      </div>
      <Card.Body>
        <Card.Subtitle className="center ct-style rounded">{props.subtitle}</Card.Subtitle>
        <Card.Text className="ct-style rounded" style={{width:"auto"}}>{props.desc}</Card.Text>
        {props.hasButton ? (
          <Button  size="lg" onClick={redirecter}>Create a Review</Button>
        ) : null}
      </Card.Body>
    </Card>
  );
}

export default SectionCards;
