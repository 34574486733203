import {useState, useEffect} from 'react';

//css imports
import "./App.css";

//jwt imports
import jwt_decode from "jwt-decode";

//react bootstrap imports
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//component imports
import NavbarTop from "./Components/UI/NavbarTop";
import FooterBar from "./Components/UI/FooterBar";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import CreateReview from "./Components/Pages/CreateReview";
import Contact from "./Components/Pages/Contact";
import UserPage from "./Components/Pages/UserPage";
import CreateAccount from "./Components/Pages/CreateAccount";
import ReviewPage from "./Components/Pages/ReviewPage";
import RecruiterPage from './Components/Pages/RecruiterPage';
import RecruiterList from './Components/Pages/RecruiterList';
import PasswordReset from './Components/Pages/PasswordReset';

//user login and authenticaion components
import Login from "./Components/Pages/Login";

//react router imports
import { Route, Routes, useNavigate } from "react-router-dom";
import ResetPassword from './Components/Pages/ResetPassword';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  
  //get token
  const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }
  
  //decodes the token
  useEffect(() => {
    const token = getToken();

    if(!token){
      setIsLoggedIn(false);
    }
    try {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if(decoded.exp < currentTime){
        setIsLoggedIn(false);
      }else{
        setIsLoggedIn(true);
        setUser(decoded);
        setUserId(decoded.userId);
      }
    }catch (err) {
      return setIsLoggedIn(false);
    }
  }, [])
  const handleLogOut = () =>{
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/login");
  }

  return (
    <Container>
      <Row>
        <Col>
          <NavbarTop onLogout={handleLogOut} isLoggedIn={isLoggedIn}/>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/about" element={<About />} />
          <Route path="/reviews" element={<ReviewPage />} />
          <Route path="/create-review"  element={<CreateReview user={user} isLoggedIn={isLoggedIn}/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/user"  element={<UserPage user={user} userId={userId} isLoggedIn={isLoggedIn} onLogout={handleLogOut}/>} />
          <Route path="/recruiters/:recruiterId" element={<RecruiterPage />} />
          <Route path="/recruiter-list" element={<RecruiterList />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/users/password-reset/:userId/:token" element={<ResetPassword/>} />
        </Routes>
        </Col>
      </Row>

      <FooterBar />
    </Container>
  );
}

export default App;
