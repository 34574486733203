import React from "react";

//css imports
import "./FooterBar.css";

//img imports
import { ReactComponent as LinkedIn } from "../../imgs/linkedin.svg";
import { ReactComponent as GitHub } from "../../imgs/github.svg";

function FooterBar() {
  //returns current year
  let currentYear = new Date().getFullYear();
  return (
    <div className="footerBar mt-1">
      <p>
        <a href="https://www.harrisonbowlby.com">Created by Harrison Bowlby</a>
        &#169;{currentYear}
      </p>
      <div className="social-icons">
        <a href="https://www.linkedin.com/in/harrison-bowlby/">
          <LinkedIn className="svg-scaler" />
        </a>
        <a href="https://github.com/hbowlby1">
          <GitHub className="svg-scaler" />
        </a>
      </div>
    </div>
  );
}

export default FooterBar;
