import React, { useState } from "react";
import Axios from "axios";

//react hook forms
import { useForm } from "react-hook-form";

//bootstrap imports
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

//css imports
import "../UI/ReviewStars.css";

function CreateReview(props) {
  //check if checkbox is checked
  const [interview, setInterview] = useState(false);
  const [recruitName, setRecruitName] = useState();
  const [recruitLinkedin, setRecruitLinkedin] = useState();
  const [recruitCompany, setRecruitCompany] = useState();
  const [companySite, setCompanySite] = useState();
  const [userReview, setUserReview] = useState();
  const [interviewReview, setInterviewReview] = useState();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  //sets the recruiter Id
  const {
    register,
    handleSubmit: handleSubmitFromHook,
    formState: { errors },
  } = useForm();

  //sets base URL for axios
  const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com/reviews" });
  const RECRUIT_URL = Axios.create({
    baseURL: "https://recruiter-buster.onrender.com/recruiters",
  });

  //checks if interview checkbox is checked
  const handleChange = (event) => {
    setInterview(event.target.checked);
  };
  //posts entered review using axios
  const onSubmit = async () => {
    try {
      let recruiterId;

      //checks if the recruiter already exists
      const existingRecruiterResponse = await RECRUIT_URL.get("/", {
        params: {
          name: recruitName,
          recruitCompany: recruitCompany,
        },
      });

      if (existingRecruiterResponse.data.length > 0) {
        // use the current recruiter ID
        recruiterId = existingRecruiterResponse.data[0]._id;

        const updatedRecruiterResponse = await RECRUIT_URL.put(`/update/${recruiterId}`, {
          overallRating: rating,
        }
        );
      } else {
        //if the recruiter does not exist create it;
        const newRecruiterResponse = await RECRUIT_URL.post(
          "/create-recruiter",
          {
            name: recruitName,
            overallRating: rating,
            companyName: recruitCompany,
          }
        );

        // get the id of the recruiter
        recruiterId = newRecruiterResponse.data._id;
      }
      const reviewResponse = await BASE_URL.post("/create-review", {
        userId: props.user.userId,
        username: props.user.fullName,
        rating: rating,
        recruiterName: recruitName,
        recruiterLinkedIn: recruitLinkedin,
        recruiterCompany: recruitCompany,
        companySite: companySite,
        reviewText: userReview,
        interview: interview,
        interviewReview: interviewReview,
        recruiterId: recruiterId,
      });

      window.location.href = "/reviews";
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {props.isLoggedIn ? (
        <>
          <h1 className="bg-secondary rounded center text-light">
            Create Review
          </h1>
          <Form
            className="bg-white rounded p-5"
            onSubmit={handleSubmitFromHook(onSubmit)}
          >
            <Form.Group className="mb-3" controlId="formRecruiterName">
              <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={
                        index <= ((rating && hover) || hover)
                          ? "on stars"
                          : "off stars"
                      }
                      onClick={() => setRating(index)}
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(rating)}
                      onDoubleClick={() => {
                        setRating(0);
                        setHover(0);
                      }}
                    >
                      <span className="star">&#9733;</span>
                    </button>
                  );
                })}
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Recruiter Name</Form.Label>
              <Form.Control
                {...register("recruiterName", { minLength: 2, maxLength: 100 })}
                type="text"
                placeholder="John Doe"
                onChange={(e) => {
                  setRecruitName(e.target.value);
                }}
              />
              {errors.recruiterName && (
                <span style={{ color: "red" }}>
                  Name must be between 2-100 characters
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRecruiterLinkedin">
              <Form.Label>Recruiters LinkedIn (if available)</Form.Label>
              <Form.Control
                {...register("recruiterLinkedIn", {
                  pattern:
                    /^https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
                })}
                type="text"
                placeholder="https://linkedin.com/recruter-name"
                onChange={(e) => {
                  setRecruitLinkedin(e.target.value);
                }}
              />
              {errors.recruiterLinkedIn && (
                <span style={{ color: "red" }}>
                  URL is incorrectly formatted
                </span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRecruiterCompany">
              <Form.Label>Recruiter's Company</Form.Label>
              <Form.Control
                {...register("recruitCompany", {
                  minLength: 3,
                  maxLength: 100,
                })}
                type="text"
                placeholder="Recruit Inc."
                onChange={(e) => {
                  setRecruitCompany(e.target.value);
                }}
              />
              {errors.recruitCompany && (
                <span style={{ color: "red" }}>
                  Company name must be between 3-100 characters
                </span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formRecruiterCompanySite">
              <Form.Label>Company Website (if available)</Form.Label>
              <Form.Control
                {...register("recruitCompanySite", {
                  pattern:
                    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                })}
                type="text"
                placeholder="https://company-website.com"
                onChange={(e) => {
                  setCompanySite(e.target.value);
                }}
              />
              {errors.recruitCompanySite && (
                <span style={{ color: "red" }}>
                  URL is not correctly formatted.
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formReview">
              <Form.Label>Your Review (be detailed)</Form.Label>
              <Form.Control
                {...register("review", { minLength: 10, maxLength: 1000 })}
                as="textarea"
                rows={5}
                onChange={(e) => {
                  setUserReview(e.target.value);
                }}
              />
              {errors.review && (
                <span style={{ color: "red" }}>
                  Review must be between 10-1000 characters
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Did you get an interview?"
                id="chkInterview"
                onChange={handleChange}
              />
            </Form.Group>
            {interview ? (
              <Form.Group className="mb-3" controlId="formReview">
                <Form.Label>How was the Interview?</Form.Label>
                <Form.Control
                  {...register("interviewReview", {
                    minLength: 10,
                    maxLength: 1000,
                  })}
                  as="textarea"
                  rows={5}
                  onChange={(e) => {
                    setInterviewReview(e.target.value);
                  }}
                />
                {errors.interviewReview && (
                  <span style={{ color: "red", margin: "0 auto" }}>
                    Review must be between 10-1000 characters
                  </span>
                )}
              </Form.Group>
            ) : (
              <></>
            )}
            <Button type="submit">Create Review</Button>
          </Form>
        </>
      ) : (
        <>
          <Card style={{ width: "18rem" }} className="center">
            <Card.Body>
              <Card.Title>Please log in to write a review.</Card.Title>
              <Card.Link href="/login">
                <Button>Login</Button>
              </Card.Link>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default CreateReview;
