import React, { useEffect, useState } from "react";

//react bootstrap Imports
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

//component import
import Loading from "../UI/Loading";

//css imports
import "./ReviewPage.css";

//axios
import Axios from "axios";
//import RecruiterCompanyFilter from "./RecruiterCompanyFilter";

const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com/reviews" });

function ReviewPage() {
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  //sets up pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage, setReviewsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (filterActive) {
          BASE_URL.get(`/?${filter}=${filterValue}`)
            .then((res) => {
              setList(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          const res = await BASE_URL.get("/");
          setList(res.data.sort((a,b) => new Date(b.date) - new Date(a.date)));
          console.log(res.data);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [filterActive, filter, filterValue]);

  //gets a list of companies for the filter
  const companyName = list.map((review) => {
    return (
      <option key={review._id} value={review.recruiterCompany}>
        {review.recruiterCompany}
      </option>
    );
  });

  //gets a list of usernames for the filter
  const getUserName = list.map((review) => {
    return (
      <option key={review._id} value={review.username}>
        {review.username}
      </option>
    );
  });

  //gets a list of recruiter names for the filter
  const getRecruitNames = list.map((review) => {
    return (
      <option key={review._id} value={review.recruiterName}>
        {review.recruiterName}
      </option>
    );
  });

  //displays the current reviews the current page it's on
  const getCurrentReviews = () => {
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    return list.slice(indexOfFirstReview, indexOfLastReview);
  };

  //gets the reviews and checks if they had an interview
  const reviews = getCurrentReviews().map((review) => {
    const hadInterview = () => {
      if (review.interview) {
        return (
          <>
            <Card.Title className="center">Interview Review</Card.Title>
            <hr />
            <Card.Text>{review.interviewReview}</Card.Text>
          </>
        );
      } else {
        return (
          <Card.Subtitle className="interview-title">
            Recruiter did not give {review.username} a review
          </Card.Subtitle>
        );
      }
    };
    return (
      <Card key={review._id} style={{ height: "auto" }}>
        <Card.Header>
          <Card.Title className="center">
            <a href={`/recruiters/${review.recruiterId}`}>
              {review.recruiterName}
            </a>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted center">
            by {review.username}
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= review.rating ? "on stars" : "off stars"}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>
          <Card.Text>{review.reviewText}</Card.Text>
          <hr />
          {hadInterview()}
          <Card.Link
            href={review.recruiterLinkedIn}
            className="btn btn-secondary"
          >
            LinkedIn
          </Card.Link>
          <Card.Link href={review.companySite} className="btn btn-secondary">
            Company Site
          </Card.Link>
        </Card.Body>
      </Card>
    );
  });
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // handles the number of pages calculation
  const totalPages = Math.ceil(list.length / reviewsPerPage);

  //generates the numbers of pages
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {showFilter && (
        <Modal show={true} onHide={() => setShowFilter(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Filters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Filter By Rating</h5>
            <Form.Select
              aria-label="Default select example"
              onChange={(value) => {
                setFilter("rating");
                setFilterValue(value.target.value);
                setFilterActive(true);
              }}
            >
              <option value="1">1 Star</option>
              <option value="2">2 Stars</option>
              <option value="3">3 Stars</option>
              <option value="4">4 Stars</option>
              <option value="5">5 Stars</option>
            </Form.Select>
            <h5>Filter By Recruiter Company</h5>
            <Form.Select
              onChange={(value) => {
                setFilter("recruiterCompany");
                setFilterValue(value.target.value);
              }}
            >
              {companyName}
            </Form.Select>

            <h5>Filter By username</h5>
            <Form.Select
              onChange={(value) => {
                setFilter("username");
                setFilterValue(value.target.value);
              }}
            >
              {getUserName}
            </Form.Select>

            <h5>Filter by Recruiter Name</h5>
            <Form.Select
              onChange={(value) => {
                setFilter("recruiterName");
                setFilterValue(value.target.value);
              }}
            >
              {getRecruitNames}
            </Form.Select>
            <Button variant="primary" onClick={() => setShowFilter(false)}>
              Close
            </Button>
            <Button
              variant="warning"
              size="sm"
              onClick={() => setFilterActive(false)}
              style={{ cursor: "pointer" }}
            >
              Clear filter
            </Button>
          </Modal.Body>
        </Modal>
      )}

      <Button variant="info" size="sm" onClick={() => setShowFilter(true)}>
        Show Filters
      </Button>
      <Button
        variant="warning"
        size="sm"
        onClick={() => setFilterActive(false)}
        style={{ cursor: "pointer" }}
      >
        Clear filter
      </Button>
      <div className="reviews">
        {loading ? (
          <Loading />
        ) : (
          <>{reviews}</>
        )}
      </div>

      {/* pagination */}
      <div>
        {pageNumbers.map((pageNumber) => (
          <Button key={pageNumber} onClick={() => handlePageChange(pageNumber)}>
            {pageNumber}
          </Button>
        ))}
      </div>
    </>
  );
}

export default ReviewPage;
