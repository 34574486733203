import React, { useState, useEffect } from "react";

import Axios from "axios";
import jwt_decode from "jwt-decode";

//bootstrap Imports
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

//import review Edit page
import ReviewEdit from "../Pages/ReviewEdit";

const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com" });

function UserPage(props) {
  const [reviewList, setReviewList] = useState([]);
  const [user, setUser] = useState(null);
  const [editingReview, setEditingReview] = useState(null);
  const [editing, setEditing] = useState(false);
  const [reviewId, setReviewId] = useState("");
  const [deleting, setDeleting] = useState(false);

  //get the user info from the token
  const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };

  useEffect(() => {
    const token = getToken();

    if (!token) {
      return;
    }
    try {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        return;
      } else {
        setUser(decoded);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  //fetches the reviews based on the user
  useEffect(() => {
    async function fetchReview() {
      if (user) {
        const response = await BASE_URL.get(`/reviews/?userId=${user.userId}`);
        setReviewList(response.data);
      }
    }
    fetchReview();
  }, [user, setReviewList]);

  const handleDeleteReview = (revId) => {
    setDeleting(true);
    setReviewId(revId);
  }

  const handleDelete = async () => {
    try {
        const response = await BASE_URL.delete(`/reviews/delete-review/${reviewId}`);
        console.log(`Deleted review ${reviewId}.`)
        setDeleting(false);
        setReviewList(reviewList.filter((review) =>review._id !== reviewId));
    }catch (error) {
        console.error(error);
    }
}
//loops through all the reviews
const userReviews = reviewList.map((review) => {
  //editing review logic
  const handleEditReview = (review) => {
    setEditingReview(review);
    setReviewId(review._id);
    setEditing(true);
  };

    return (
      <Card key={review._id}>
        <Card.Header>
          <Card.Title className="center">{review.recruiterName}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted center">
            by {review.username}
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Card.Title>Recruiter Review</Card.Title>
          <Card.Text>{review.reviewText}</Card.Text>
          <Card.Title>Interview Review</Card.Title>
          <Card.Text>{review.interviewReview}</Card.Text>
        </Card.Body>
        <Button onClick={() => handleEditReview(review)}>Edit Review</Button>
        <Button variant="danger" onClick={() => handleDeleteReview(review._id)}>Delete Review</Button>
      </Card>
    );
  });
  return (
    <>
      {props.isLoggedIn ? (
        <>
          <Card>
            <Card.Body>
              <Card.Title>Welcome, {props.user.fullName}</Card.Title>
              <Card.Subtitle>reviews written {reviewList.length}</Card.Subtitle>
              <Button onClick={props.onLogout}>Logout</Button>
            </Card.Body>
          </Card>
          {editing && (
            <ReviewEdit
              review={editingReview}
              reviewId = {reviewId}
              onClose={() => {
                setEditingReview(null);
                setEditing(false);
              }}
            />
          )}
          {deleting && (
            <Modal show={true} onHide={() => setDeleting(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete your review?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button variant="danger" onClick={handleDelete}>Yes</Button>
                <Button variant='primary' onClick={() => setDeleting(false)}>No</Button>
              </Modal.Body>
            </Modal>
          )}
          <div>{userReviews}</div>
        </>
      ) : (
        <Card>
          <Card.Body>
            <Card.Text>You are not logged in</Card.Text>
            <Card.Link href="/login">Please log in here</Card.Link>
          </Card.Body>
        </Card>
      )}
    </>
  );
}


export default UserPage;
