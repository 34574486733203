import React from "react";

//react imports
import SectionCards from "../UI/SectionCards";

//image imports
import titleImage from "../../imgs/titleImg_640x426.jpg";

let titleSectionContents = {
    title: "A Job Recuriter Review Site",
    img: titleImage,
    subtitle: 'A site dedicated to seperating good recruiters from lazy recruiters',
    desc: "This webpage will help you determine if you are working with a good or bad recruiter. Today, many recruiters are lazy and do not care about getting you a job. Other's actually care and will work with you until you score a job. However, they are few and far between. This website was designed to break this problem and finally give employees a fair opportunity to actually find a job. This is a user curated review website that will help you, me and the rest of the world find a recruiter that fits their needs.",
    alt: "Image of for hire sign"
}

function Home() {
  return (
    <div>
      <section>
        <SectionCards {...titleSectionContents} hasButton={true}/>
      </section>
    </div>
  );
}

export default Home;
