// RecruiterPage.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Loading from "../UI/Loading";

import Card from "react-bootstrap/Card"

const BASE_URL = axios.create({ baseURL: "https://recruiter-buster.onrender.com" });

const RecruiterPage = () => {
  const { recruiterId } = useParams();
  const [recruiter, setRecruiter] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [totalRating, setTotalRating] = useState(0);

  useEffect(() => {
    const fetchRecruiterAndReviews = async () => {
      try {
        const recruiterResponse = await BASE_URL.get(
          `/recruiters/${recruiterId}`
        );
        const reviewsResponse = await BASE_URL.get(`/reviews`, {
          params: {
            recruiterId: recruiterId,
          },
        });

        setRecruiter(recruiterResponse.data);
        setReviews(reviewsResponse.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchRecruiterAndReviews();
  }, [recruiterId]);

  useEffect(() => {
    if (recruiter) {
      let sum = 0;

      for (const rating of recruiter.overallRating) {
        sum += rating;
      }

      setTotalRating(Math.round((sum / recruiter.overallRating.length) * 10)/10);
    }
  }, [recruiter]);

  if (!recruiter) {
    return <Loading />;
  }

  return (
    <div>
      <h1>{recruiter.name}</h1>
      <h1>{totalRating}</h1>
      <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= totalRating ? "on stars" : "off stars"}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>
      <h2>{recruiter.companyName}</h2>
      {/* Display other recruiter information if needed */}
      <h3>Reviews:</h3>
      {reviews.map((review) => (
              <Card key={review._id}>
              <Card.Header>
                <Card.Title className="center">{review.recruiterName}</Card.Title>
                <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= review.rating ? "on stars" : "off stars"}
                >
                  <span className="star">&#9733;</span>
                </button>
              );
            })}
          </div>
                <Card.Subtitle className="mb-2 text-muted center">
                  by {review.username}
                </Card.Subtitle>
              </Card.Header>
              <Card.Body>
                <Card.Title>Recruiter Review</Card.Title>
                <Card.Text>{review.reviewText}</Card.Text>
                <Card.Title>Interview Review</Card.Title>
                <Card.Text>{review.interviewReview}</Card.Text>
              </Card.Body>
            </Card>
      ))}
    </div>
  );
};

export default RecruiterPage;
