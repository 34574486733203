import React, {useState} from 'react'
import Axios from "axios"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useParams } from 'react-router'


const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com/users" });

function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const {userId, token} = useParams();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(password === confirmPassword){
                const response = await BASE_URL.put(`/password-reset/${userId}/${token}`, {password:confirmPassword});
                console.log('password updated');
                window.location.href ='/login';
            }
        } catch (error) {
            console.error(error);
        }
    }
  return (
    <Form onSubmit={handleSubmit} className="bg-secondary text-light rounded">
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label className="text-dark">Password:</Form.Label>
        <Form.Control
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
      <Form.Label className="text-dark">Confirm Password:</Form.Label>
        <Form.Control
          type="password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit">Reset Password</Button>
    </Form>
  )
}

export default ResetPassword