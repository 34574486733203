import React from "react";

//bootstrap imports
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

//css imports
import "./NavbarTop.css";

//react router bootstrap import
import { LinkContainer } from "react-router-bootstrap";

function NavbarTop(props) {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand href="#home">RB</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link href="#home">Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/reviews">
                <Nav.Link href="#reviews">Reviews</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/recruiter-list">
                <Nav.Link href="#recruiter-list">Recruiters</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/create-review">
                <Nav.Link href="#create-review">Create Review</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link href="#about">About</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link href="#contact">Contact</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              {props.isLoggedIn ? (
                <>
                <LinkContainer to="/user">
                  <Nav.Link href="#userProfile">Profile</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/login">
                  <Nav.Link href="#login" onClick={props.onLogout}>Logout</Nav.Link>
                </LinkContainer>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <Nav.Link eventKey={2} href="#Login">
                      Login
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/create-account">
                    <Nav.Link eventKey={2} href="#create-account">
                      Sign-Up
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarTop;
