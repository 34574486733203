import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//bootstrap imports
import Form from "react-bootstrap/Form";

//component imports
import Button from "react-bootstrap/Button";

//react hook forms import
import { useForm } from 'react-hook-form';

function CreateAccount(props) {
  //validation for frontend using react-hook-form
  const { register, handleSubmit: handleSubmitFromHook, watch, formState: {errors} } = useForm();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [dob, setDOB] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState();

  //sets base URL for axios
  const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com/users" });

  //navigate constant for navigating to a new page
  const navigate = useNavigate();

  //setting watch for react hook forms on password
  watch("password");

  //post user
  const onSubmit = (e) => {
    if(password !== confirmPassword){
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
      BASE_URL.post("/create-user", {
        firstName: firstName,
        lastName: lastName,
        email: email,
        dob: dob,
        password: password,
      })
        .then((res) => {
          setTimeout(() => {
            navigate('/login');
          }, 1000)
        })
        .catch((err) => {
          console.error(err);
        });
      //sets a little time to make the app look like it's loading
    }

  };
  return (
    <>
      <h1 className="center bg-secondary text-light rounded p-3">
        Create Account
      </h1>
      <Form className="bg-white rounded p-5" onSubmit={handleSubmitFromHook(onSubmit)}>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            {...register("firstName", {minLength: 2, maxLength: 100})}
            type="text"
            placeholder="John"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          {errors.firstName && <span style={{color:"red"}}>First name must be between 2-100 characters.</span>}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            {...register("lastName", {minLength: 2, maxLength: 100})}
            type="text"
            placeholder="Doe"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          {errors.lastName && <span style={{color:"red"}}>Last name must be between 2-100 characters.</span>}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicDOB">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control
            {...register("dob", {required: true})}
            type="date"
            onChange={(e) => {
              setDOB(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            {...register("email", {required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/})}
            type="email"
            placeholder="Enter email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {errors.email && <span style={{color:"red"}}>Email is not in the correct format or already exists.</span>}
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            {...register("password", { required: true, minLength: 8, maxLength: 24, pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=[\w\d#?!@$%^&*-_]).{8,}$/ })}
            type="password"
            placeholder="Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.password && <span style={{color:"red"}}>Password must have at least 1 number and 1 capital letter.</span>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            onChange={(e) => {
              //if (confirmPassword === password) {
              setPassword(e.target.value);
              //} else {
              // return <p>passwords do not match</p>;
              //}
            }}
          />
        </Form.Group>
        <div style={{ color: 'red' }}>{passwordError}</div>
        <Button type="submit">Create Account</Button>
      </Form>
    </>
  );
}

export default CreateAccount;
