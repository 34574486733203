import Axios from "axios";

const token = localStorage.getItem("token");
if(token) {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401){
            localStorage.removeItem("token");
            console.log("verified");
        }
    }
)

