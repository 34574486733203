import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Axios from "axios";

const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com" });

function ReviewEdit(props) {
  const [recruiterName, setRecruiterName] = useState(props.review.recruiterName);
  const [reviewText, setReviewText] = useState(props.review.reviewText);
  const [recruiterLinkedIn, setRecruiterLinkedIn] = useState(props.review.recruiterLinkedIn);
  const [recruiterCompany, setRecruiterCompany] = useState(props.review.recruiterCompany);
  const [companySite, setCompanySite] = useState(props.review.companySite);
  const [interview, setInterview] = useState(props.review.interview);
  const [interviewReview, setInterviewReview] = useState(props.review.interviewReview);
  const [rating, setRating] = useState(props.review.rating);
  const [hover, setHover] = useState(props.review.rating);

  const handleSubmit = async (event) => {

    const updatedReview = {
      rating,
      recruiterName,
      reviewText,
      recruiterLinkedIn,
      recruiterCompany,
      companySite,
      interview,
      interviewReview,

    };

    try {
      const response = await BASE_URL.put(`/reviews/update-review/${props.reviewId}`, updatedReview);
      props.onClose();
      window.href.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (event) => {
    setInterview(event.target.checked);
  };

  return (
    <Modal show={true} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Review</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formRecruiterName">
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={
                      index <= ((rating && hover) || hover)
                        ? "on stars"
                        : "off stars"
                    }
                    onClick={() => setRating(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                    onDoubleClick={() => {
                      setRating(0);
                      setHover(0);
                    }}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Recruiter Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter recruiter name"
              value={recruiterName}
              onChange={(e) => setRecruiterName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Recruiter LinkedIn (If Available)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Linkedin"
              value={recruiterLinkedIn}
              onChange={(e) => setRecruiterLinkedIn(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Recruiter Company</Form.Label>
            <Form.Control
              type="text"
              placeholder="test company LLC"
              value={recruiterCompany}
              onChange={(e) => setRecruiterCompany(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Recruiter Company</Form.Label>
            <Form.Control
              type="text"
              placeholder="www.recruitcompany.com"
              value={companySite}
              onChange={(e) => setCompanySite(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Review</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter review text"
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Did you get an interview?"
              id="chkInterview"
              value={interview}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Review</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter review text"
              value={interviewReview}
              onChange={(e) => setInterviewReview(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ReviewEdit;
