import React, { useState } from "react";
import Button from "react-bootstrap/esm/Button";

import Form from "react-bootstrap/Form";
import Axios from "axios";

const BASE_URL = Axios.create({ baseURL: "https://recruiter-buster.onrender.com/users" });

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await BASE_URL.post("/password-reset", { email });
      setSent(true);
      window.location.href = "/login"
    } catch (error) {}
  };
  return (
    <>
    <Form onSubmit={handleSubmit} className="bg-secondary text-light rounded">
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="text-dark">Email:</Form.Label>
        <Form.Control
          type="email"
          placeholder="test@test.com"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit">Reset Password</Button>
    </Form>
    {sent ? (
      <div className="text-light">Email was sent to {email}</div>
    ): (
      <></>
    )}
    </>
  );
}

export default PasswordReset;
